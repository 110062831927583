import { Controller } from "stimulus"
import Cookie from "js.cookie"
import debounce from 'lodash/debounce'

export default class extends Controller {

  static values = {
    href: String
  }

  static targets = ["perPage", "sortBy"]

  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }
  redirect() {
    window.location = this.hrefValue
  }

  list() {
    // Cookie.set("bp_viewmode", "listview")
    // window.location.reload(true);
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Set or update the "viewmode" parameter
    currentUrl.searchParams.set("viewmode", "listview");

    // Update the cookie
    Cookie.set("bp_viewmode", "listview");

    // Reload the page with the updated URL
    window.location.href = currentUrl.toString();
  }
  image() {
    // Cookie.set("bp_viewmode", "imagelistview")
    // window.location.reload(true);
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Set or update the "viewmode" parameter
    currentUrl.searchParams.set("viewmode", "imagelistview");

    // Update the cookie
    Cookie.set("bp_viewmode", "imagelistview");

    // Reload the page with the updated URL
    window.location.href = currentUrl.toString();
  }
  card() {
    // Cookie.set("bp_viewmode", "cardview")
    // window.location.reload(true);

    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Set or update the "viewmode" parameter
    currentUrl.searchParams.set("viewmode", "cardview");

    // Update the cookie
    Cookie.set("bp_viewmode", "cardview");

    // Reload the page with the updated URL
    window.location.href = currentUrl.toString();

  }
  map() {
    // Cookie.set("bp_viewmode", "mapview")

    // window.location.reload(true);
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Set or update the "viewmode" parameter
    currentUrl.searchParams.set("viewmode", "mapview");
    currentUrl.searchParams.set("per_page", 1000);
    // Update the cookie
    Cookie.set("bp_viewmode", "mapview");
    Cookie.set("bp_perpage", 1000)
    // Reload the page with the updated URL
    window.location.href = currentUrl.toString();
  }
  perpage() {
    // Cookie.set("bp_perpage", this.perPageTarget.value)
    // window.location.reload(true);
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Set or update the "viewmode" parameter
    currentUrl.searchParams.set("per_page", this.perPageTarget.value);
    // Update the cookie
    Cookie.set("bp_perpage", this.perPageTarget.value)
    // Reload the page with the updated URL
    window.location.href = currentUrl.toString();
  }
  sortByDesktop() {
    Cookie.set("bp_sort", this.sortByTarget.value)
    console.log('desktop sort');
    console.log(this.sortByTarget.value);
    window.location.href = this.sortByTarget.value;
    // window.location.reload(true);
  }
  sortByMobile() {
    Cookie.set("bp_sort", this.sortByTarget.value);
    console.log('mobile sort');
    window.location.href = this.sortByTarget.value;
    // window.location.reload(true);
  }

  guestCountPlus() {
    console.log('guestCountPlus');
    const guestCountElement = document.getElementById('guest-quantity');
    // const sortbyElement = document.getElementById('sort');
    // sortbyElement.value = 'max_occupancy asc';
    const guestCount = parseInt(guestCountElement.value);
    if (guestCount < 15) {
      guestCountElement.value = guestCount + 1;
    }
  }
  guestCountMinus() {
    console.log('guestCountMinus');
    // const sortbyElement = document.getElementById('sort');
    // sortbyElement.value = 'max_occupancy asc';
    const guestCountElement = document.getElementById('guest-quantity');
    const guestCount = parseInt(guestCountElement.value);
    if (guestCount > 0) {
      guestCountElement.value = guestCount + - 1;
    }
  }


  guestCountPlusMobile() {
    console.log('guestCountPlusMobile');
    const guestCountElement = document.getElementById('mobile-guest-quantity');
    // const sortbyElement = document.getElementById('sort-mobile');
    // sortbyElement.value = 'max_occupancy asc';
    const guestCount = parseInt(guestCountElement.value);
    if (guestCount < 15) {
      guestCountElement.value = guestCount + 1;
    }
  }
  guestCountMinusMobile() {
    console.log('guestCountMinusMobile');
    // const sortbyElement = document.getElementById('sort-mobile');
    // sortbyElement.value = 'max_occupancy asc';
    const guestCountElement = document.getElementById('mobile-guest-quantity');
    const guestCount = parseInt(guestCountElement.value);
    if (guestCount > 0) {
      guestCountElement.value = guestCount + - 1;
    }
  }


  bedroomCountPlus() {
    console.log('bedroomCountPlus');
    const bedroomCountElement = document.getElementById('bedroom-quantity');
    // const sortbyElement = document.getElementById('sort');
    // sortbyElement.value = 'bedrooms asc';
    const bedroomCount = parseInt(bedroomCountElement.value);
    if (bedroomCount < 15) {
      bedroomCountElement.value = bedroomCount + 1;
    }
  }
  bedroomCountMinus() {
    console.log('bedroomCountMinus');
    // const sortbyElement = document.getElementById('sort');
    // sortbyElement.value = 'bedrooms asc';
    const bedroomCountElement = document.getElementById('bedroom-quantity');
    const bedroomCount = parseInt(bedroomCountElement.value);
    if (bedroomCount > 0) {
      bedroomCountElement.value = bedroomCount + - 1;
    }
  }

  bedroomCountPlusMobile() {
    console.log('bedroomCountPlusMobile');
    const bedroomCountElement = document.getElementById('mobile-bedroom-quantity');
    // const sortbyElement = document.getElementById('sort-mobile');
    // sortbyElement.value = 'bedrooms asc';
    const bedroomCount = parseInt(bedroomCountElement.value);
    if (bedroomCount < 15) {
      bedroomCountElement.value = bedroomCount + 1;
    }
  }
  bedroomCountMinusMobile() {
    console.log('bedroomCountMinus');
    // const sortbyElement = document.getElementById('sort-mobile');
    // sortbyElement.value = 'bedrooms asc';
    const bedroomCountElement = document.getElementById('mobile-bedroom-quantity');
    const bedroomCount = parseInt(bedroomCountElement.value);
    if (bedroomCount > 0) {
      bedroomCountElement.value = bedroomCount + - 1;
    }
  }


  submitFilterForm() {
    console.log('submitform new')
    console.log(innerWidth)
    if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
      console.log("it's a phone"); // your code here
      $('#available_modal')[0].value = $('#check_in')[0].value + "," + $('#check_out')[0].value;

    } else {
      console.log('it is not a phone');
      // document.getElementById('filter_form').submit();
      document.getElementById('searchSubmit').click();
    }
    if (window.innerWidth > 600) {

    }
  }

  submitFilterFormWithDebounce() {
    //   clearTimeout(this.timeout)
    console.log(window.innerWidth);
    //   if (window.innerWidth > 600) {
    //     this.timeout = setTimeout(() => {
    //       document.getElementById('filter_form').submit();
    //     }, 300)
    //   }
    // }
  }
}